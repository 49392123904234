
$base-color-dark: #343a40;
$side-menu-color: #e0a800;

.layout-body{}
.page-background{}
.login-box{width: 500px;box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;transition: 0.3s;}
.small-font{font-size: 13px;}
.smaller-font{font-size: 11px;}

.top-10{ margin-top:10px; }
.top-20{ margin-top:20px; }
.top-40{ margin-top:40px; }
.top-100{margin-top:100px}
.top-center{margin-top: 10%!important;}

.is-bold{font-weight: bold;}
.is-centered{text-align: center;}
.pointer {cursor: pointer;}

.height-100{min-height: 100px;}

.border-silver-1{border:  1px silver solid;}
.border-gains-1{border:  1px gainsboro solid;}

.text-color-black{color:  black;}
.user-title{color:  white; font-size: 13px; font-weight: bold;}
.margin-auto{margin:auto;}

.fat-i-title-sm {font-style: italic;}
.fat-title {font-weight: bold; font-size: 17px; }

.dark-mode-back{background-color: $base-color-dark; color: white;}

.center-content {  margin: 0 auto;  width: 50%;  padding: 15px;}

.info-box-sel{ opacity: 0.8;}
.info-box-sel:hover{cursor: pointer; border: 2px black solid;  opacity: 1;}

.sidebar-dark-primary .nav-sidebar>.nav-item>.nav-link.active{
	background-color: #3f6791;
    color: #fff;
}

.table-condensed>thead>tr>th, .table-condensed>tbody>tr>th, .table-condensed>tfoot>tr>th, .table-condensed>thead>tr>td, .table-condensed>tbody>tr>td, .table-condensed>tfoot>tr>td{
    padding: 6px;
    font-size: 15px;
}

.padding-20{padding: 20px;}

/* Cards del dashboard*/ 
.app-conf-button{box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px; border-color: lightgray;}
.app-conf-button:hover {cursor: pointer; border: 1px black solid;  opacity: 1; background-color: #F0F0F0;}
.app-button{box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;border-color: gray;}
.app-button:hover {cursor: pointer; border: 1px #80b5d3 solid;  opacity: 1; background-color: aliceblue; }

.lightback {background-image: linear-gradient(45deg, #dbdbdb 25%, #c7c7c7 25%, #c7c7c7 50%, #dbdbdb 50%, #dbdbdb 75%, #c7c7c7 75%, #c7c7c7 100%);
background-size: 5.66px 5.66px;}



@media only screen and (max-width: 500px) {
  .login-box {
    width: 380px;
    }
}